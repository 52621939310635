import React, { Fragment } from "react";
import _ from "lodash";
import Section from "../ui/section/Section";
import Article from "../ui/articles/Article";
import Heading from "../ui/text/Heading";
import parser from "react-html-parser";
import ContentBlock from "../ui/cards/ContentBlock";
import Container from "../ui/containers/Container";
import Grid from "../ui/grid/Grid";
import Spacer from "../ui/structure/Spacer";

export default function Standard({ data }) {
  const sectionName = data.title
    ? data.title.toLowerCase().replace(" ", "-")
    : "intro";

  const renderContentBlocks = contentBlocks => {
    return contentBlocks.map(contentBlock => {
      return <ContentBlock data={contentBlock} />;
    });
  };

  return (
    <Container>
      <Section name={sectionName}>
        <Article>
          {data.title && (
            <Heading as="h2" primary centered>
              {data.title}
            </Heading>
          )}

          {data.content && parser(data.content)}
        </Article>

        {!_.isEmpty(data.contentBlocks) && (
          <Fragment>
            <Spacer />
            <Grid>{renderContentBlocks(data.contentBlocks)}</Grid>
          </Fragment>
        )}
      </Section>
    </Container>
  );
}
