import styled from "styled-components";

const NavHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => (props.visible === true ? "15px 0 5px 0" : "20px 0")};
  @media (max-width: 1023px) {
    justify-content: center;
    padding: 13px 0 8px 0;
  }
`;

export default NavHeader;
