import styled from "styled-components";

const NavList = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin-top: ${props => (props.visible === true ? "5px" : "12px")};

  @media (max-width: 1023px) {
    display: ${props => (props.isActive ? "block" : "none")};
    background: #f4faff;
    padding-top: 10px;
    margin: 0px;
  }
`;

export default NavList;
