import styled from "styled-components";

const TeamBlock = styled.div`
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: -14px;
    height: 100%;
    width: 1px;
    background: #00000054;
    display: ${props => (props.hasBorderRight ? "block" : "none")};

    @media (max-width: 762px) {
      display: none;
    }
  }
`;

export default TeamBlock;
