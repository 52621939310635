import React, { useState, useEffect } from "react";
import Routes from "../routes/Routes";
import Navbar from "./ui/navbar/Navbar";
import Footer from "./ui/footer/Footer";
import history from "../utils/history/history";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes />
      <Footer />
    </div>
  );
}

export default App;
