import "./hamburger.scss";
import React from "react";

export default function Hamburger(props) {
  const styles = [
    "hamburger",
    "hamby",
    "hamburger--elastic",
    props.isActive ? "is-active" : "closed",
    !props.visible && "visible"
  ];

  return (
    <div onClick={props.onClick} className={`${styles.join(" ")}`} id="hamby">
      <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span>
    </div>
  );
}
