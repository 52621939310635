import styled from "styled-components";

const NavItem = styled.li`
  display: flex;
  cursor: pointer;
  justify-content: center;
  font-weight: 700;
`;

export default NavItem;
