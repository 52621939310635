import styled from "styled-components";

const Paragraph = styled.p`
  color: ${props => (props.secondary ? "#fff" : "#35375B")};
  font-size: ${props => (props.large ? "20px" : "16px")};
  margin: 10px 0 27px 0;
  margin-top: ${props => (props.margintop ? "30px" : "0")};
  line-height: 26px;
  text-align: ${props => (props.centered ? "center" : "auto")};
`;

export default Paragraph;
