import styled from "styled-components";

const Heading = styled.h1`
  color: ${props => (props.primary ? "#14B3EB" : "white")};
  text-align: ${props => (props.centered ? "center" : "auto")};
  text-shadow: ${props =>
    props.shadow ? "0px 3px 10px rgba(0, 0, 0, 0.2)" : "none"};

  @media (max-width: 762px) {
    text-align: center;
  }
`;

Heading.h1 = Heading;
Heading.h2 = Heading.withComponent("h2");
Heading.h3 = Heading.withComponent("h3");
Heading.h4 = Heading.withComponent("h4");
Heading.h5 = Heading.withComponent("h5");
Heading.h6 = Heading.withComponent("h6");

export default Heading;
