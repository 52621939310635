import React, { Fragment } from "react";
import _ from "lodash";
import { apiImagePath } from "../../utils/functions/index";
import Section from "../ui/section/Section";
import Article from "../ui/articles/Article";
import Heading from "../ui/text/Heading";
import parser from "react-html-parser";
import ContentBlock from "../ui/cards/ContentBlock";
import Container from "../ui/containers/Container";
import Grid from "../ui/grid/Grid";
import GridItem from "../ui/grid/GridItem";
import Spacer from "../ui/structure/Spacer";

export default function Vertical({ data }) {
  const sectionName = data.title
    ? data.title.toLowerCase().replace(" ", "-")
    : "intro";

  const renderContentBlocks = contentBlocks => {
    return contentBlocks.map(contentBlock => {
      return <ContentBlock data={contentBlock} />;
    });
  };

  return (
    <Container>
      <Section name={sectionName}>
        <Article>
          <Grid>
            <GridItem sizes={["col-lg-7"]}>
              {data.title && (
                <Heading as="h2" primary>
                  {data.title}
                </Heading>
              )}

              {data.content && parser(data.content)}
            </GridItem>

            <GridItem sizes={["col-lg-5"]}>
              <img src={apiImagePath(data.images)} alt="" />
            </GridItem>
          </Grid>
        </Article>

        {!_.isEmpty(data.contentBlocks) && (
          <Fragment>
            <Spacer />
            <Grid>{renderContentBlocks(data.contentBlocks)}</Grid>
          </Fragment>
        )}
      </Section>
    </Container>
  );
}
