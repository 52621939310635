import styled from "styled-components";

const Logo = styled.img.attrs({
  src: props =>
    props.theme === "dark"
      ? " /images/logo/my2morrow-logo-colour.svg"
      : "/images/logo/my2morrow-logo-white.svg"
})`
  max-width: 185px;
  transition: all 150ms;

  @media (max-width: 1023px) {
    max-width: ${props => (!props.visible ? "80px" : "185px")};
  }
`;

export default Logo;
