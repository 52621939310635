import React from "react";
import Container from "../ui/containers/Container";
import Heading from "../ui/text/Heading";
import Paragraph from "../ui/text/Paragraph";
import Grid from "../ui/grid/Grid";
import GridItem from "../ui/grid/GridItem";
import CardDefault from "../ui/cards/CardDefault";
import Button from "../ui/buttons/Button";
import ButtonList from "../ui/buttons/ButtonList";
import { downloadPDF } from "../../utils/functions/index";
import { BASE_URL } from "../../utils/paths";

export default function GettingStarted() {
  return (
    <div style={{ margin: "0 0 100px 0 " }}>
      <Container>
        <Grid>
          <GridItem sizes={["col-lg-6", "col-md-6"]}>
            <CardDefault centered theme="orange">
              <Heading shadow as="h3">
                GETTING STARTED
              </Heading>

              <Paragraph secondary>
                Register your personal details and complete a brief analysis
                questionnaire.
              </Paragraph>

              <Button type="redirect" to="/start-a-conversation">
                START CONVERSATION
              </Button>

              <Paragraph secondary margintop="true">
                Once you have completed the questionnaire, a career counsellor
                will contact you via e-mail.
              </Paragraph>
            </CardDefault>
          </GridItem>

          <GridItem sizes={["col-lg-6", "col-md-6"]}>
            <CardDefault centered theme="lime">
              <Heading as="h3" shadow>
                PLANNING FOR <br /> SUCCESS - BROCHURE
              </Heading>

              <ButtonList margintop="true">
                <Button
                  type="viewPDF"
                  href={`/pdf/My2Morrow-Career-Counselling-v3.pdf`}
                >
                  VIEW PDF
                </Button>

                <Button
                  type="download"
                  onClick={pdf =>
                    downloadPDF("/pdf/My2Morrow-Career-Counselling-v3.pdf")
                  }
                >
                  DOWNLOAD PDF
                </Button>
              </ButtonList>
            </CardDefault>
          </GridItem>
        </Grid>
      </Container>
    </div>
  );
}
