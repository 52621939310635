import styled from "styled-components";

const themes = {
  orange: "#E76A2C",
  lime: "#A1D742"
};

const CardDefault = styled.article`
  background: ${props => (props.theme ? themes[props.theme] : "#fff")};
  text-align: ${props => (props.centered ? "center" : "auto")};
  color: ${props => (props.theme ? "#fff" : "#35375B")};
  padding: 60px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 440px;
  align-items: center;

  @media (max-width: 760px) {
    padding: 20px;
  }
`;

export default CardDefault;
