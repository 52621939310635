import styled from "styled-components";

const BannerParalax = styled.div`
  background: url(${props => props.image});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: ${props => (props.centered ? "center" : "auto")};
  background-attachment: fixed;
`;

export default BannerParalax;
