import axios from "axios";

export default axios.create({
  // baseURL: "http://127.0.0.1:8000/api/v1",
  // baseURL: "https://admin.my2morrow.stageoptimal.co.za/api/v1",
  // baseURL: "https://admin.my2morrow.com/api/v1",
  baseURL: "https://admin.my2morrow.optimalonline.co.za/api/v1",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});
