import React from "react";
import _ from "lodash";
import Module from "./Module";
import Loader from "../ui/loader/Loader";

export default function PageModules({ page }) {
  const renderPageModules = page => {
    return page.data.modules.map(pageModule => {
      return <Module type={pageModule.type} data={pageModule} />;
    });
  };

  const modules = !_.isEmpty(page) ? (
    renderPageModules(page)
  ) : (
    <Loader fullscreen />
  );

  return modules;
}
