import React from "react";
import Container from "../ui/containers/Container";
import Section from "../ui/section/Section";
import Heading from "../ui/text/Heading";
import Paragraph from "../ui/text/Paragraph";
import NavSpacer from "../ui/structure/NavSpacer";
import Button from "../ui/buttons/Button";

export default function ThankYou() {
  return (
    <Container>
      <NavSpacer />
      <Section>
        <Heading as="h1" primary>
          Thank you for your submission!
        </Heading>

        <Paragraph>We will get back to you as soon as possible.</Paragraph>

        <Button type="redirect" to="/">
          RETURN HOME
        </Button>
      </Section>
    </Container>
  );
}
