import styled from "styled-components";

const Section = styled.section.attrs({
  id: props => props.name
})`
  padding: 120px 0 90px 0;
  padding-bottom: ${props => (props.noPaddingBottom ? "0" : "90px")};
  @media (max-width: 762px) {
    padding: 90px 0 70px 0;
  }
`;

export default Section;
