import styled from "styled-components";

const ButtonList = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${props => (props.marginTop ? "30px" : "0")};

  button,
  a {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    button,
    a {
      margin-right: 0;
    }
  }

  button {
    margin-bottom: 20px;
  }
`;

export default ButtonList;
