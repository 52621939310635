import React from "react";
import styled from "styled-components";
import Heading from "../text/Heading";
import Paragraph from "../text/Paragraph";
import Button from "../buttons/Button";
import Container from "../containers/Container";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./Slider.scss";

export const SliderContainer = styled.a`
  position: relative;
`;

const SliderItem = styled.div`
  height: 100vh;
  background: url(${props => props.image});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1450px) {
    justify-content: flex-end;
    padding: 0 20px 60px 20px;
  }

  @media (max-width: 960px) {
    justify-content: center;
    padding: auto;
  }

  @media (max-width: 768px) {
    padding: 0 20px;
    text-align: center;
  }
`;

const SliderItemContent = styled.div`
  width: 40%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Slider = props => {
  const galleryItems = () => {
    return props.items.map(item => {
      return (
        <SliderItem
          image={window.innerWidth < 762 ? item.imageMobie : item.image}
        >
          <Container>
            <SliderItemContent>
              <Heading shadow as="h1" style={{ textTransform: 'uppercase' }}>
                {item.title}
              </Heading>

              <Paragraph secondary large>
                {item.subtitle}
              </Paragraph>

              <Button type="redirect" to={item.link} target="_blank">
                {item.button}
              </Button>
            </SliderItemContent>
          </Container>
        </SliderItem>
      );
    });
  };

  return (
    <AliceCarousel
      mouseTrackingEnabled
      buttonsDisabled
      items={galleryItems()}
      stagePadding={{ paddingTop: 0 }}
      autoPlay={true}
      autoPlayInterval={5000}
      stopAutoPlayOnHover={false}
    />
  );
};
