import React from "react";
import styled from "styled-components";

const icons = {
  light: {
    facebook: "/images/social-icons/facebook.svg",
    instagram: "/images/social-icons/instagram.svg",
    skype: "/images/social-icons/skype.svg"
  },
  dark: {
    facebook: "/images/social-icons/dark/facebook.svg",
    instagram: "/images/social-icons/dark/instagram.svg",
    skype: "/images/social-icons/dark/skype.svg"
  }
};

export const IconImage = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: url(${props => icons[props.theme][props.name]});
  background-repeat: no-repeat;
  background-position: center;
  border: none !important;
  outline: none !important;
`;

export const IconList = styled.ul`
  display: ${props => (props.hideonmobile ? "none" : "flex")};
  justify-content: ${props => (props.centered ? "center" : "auto")};
  margin-top: ${props => (props.margintop ? "30px" : "auto")};
  margin-bottom: ${props => (props.marginbottom ? "60px" : "auto")};
  padding: 0;
`;

export const IconListItem = styled.li`
  list-style: none;
  padding-right: 10px;
  &:last-child {
    padding-right: 0;
  }
`;

export const Icon = props => (
  <IconListItem>
    <IconImage {...props} />
  </IconListItem>
);
